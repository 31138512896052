import Button from '@/components/Button';
import { FC } from 'react';
import style from './home.module.css';
import Link from 'next/link';

const HomePage: FC = () => {
  return (
    <section className={style.home_container}>
      <article className={style.home_left}>
        <h1 style={{ paddingBottom: '5px'}}>Redescubre la <br></br>Ciudad de Buenos Aires:</h1>
        <h2>Un Viaje por sus Calles</h2>
        <small style={{ padding: '45px 0', letterSpacing: '0.125em' }}>Explora los orígenes y significados del trazado urbano de la Capital Federal</small>
        <Link href="/interactiveMap">
          <Button>
              Abrir Mapa
          </Button>
        </Link>
      </article>
      <article className={style.home_right}>
        <strong>Bienvenidos a deCalles.com, una plataforma diseñada para redescubrir y explorar las calles de la Ciudad de Buenos Aires. Este proyecto cultural y educativo permite a los usuarios visualizar calles según diversas categorías, como Política, Militar, Religión, Conmemoraciones, Ciudades, entre otras, así como buscar e identificar cualquier calle en particular.</strong>
        <p>Además, publicamos artículos en nuestro blog que ofrecen una mirada profunda a la historia y la toponimia de calles específicas o series de calles con conexiones interesantes.</p>
        <p>Uno de los objetivos de estos artículos es destacar cómo las contradicciones de la historia argentina se reflejan en el trazado urbano de Buenos Aires, proporcionando una oportunidad única para apreciar la rica historia cultural de esta ciudad llena de significado y tradición.</p>
      </article>
    </section>
  );
};

export default HomePage;