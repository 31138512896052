import React, { FC, ReactNode } from 'react';
import styles from './button.module.css';

const Button: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <button className={styles.button} onClick={() => console.log('Next button clicked')}>
            {children}
        </button>
    );
};

export default Button;